import React, {useState} from 'react';
import { Row, Col, Container, Button} from "react-bootstrap"
import { Link, navigate } from 'gatsby'
import icon from '../../images/icon.png'

const UserSidebar = ({show, children, closeFn, state, pageInfo, token}) => {
  const [subMenuShow, setSubMenuShow] = useState(false);

  const showSubMenuClick = (e) => {
    const element = document.querySelector(`#${e.target.id} + ul`)
    const span = document.querySelector(`#${e.target.id} span`)
    span.classList.toggle('oi-chevron-bottom');
    span.classList.toggle('oi-chevron-right');
    element.classList.toggle('hide');
  }

  const onSubMenuClick = (e) => {
   }
  
  
  return (
    <>
      <Container className={`userSidebar`}>
        <div className="text-center my-3 logoArea">
            <img className='logo' src={icon}/>
        </div>
        <div className="userNav">
            <hr/>
            <ul>
              <li>
                <Link to="/profile/myProfile" className="text-muted"><span className={'oi oi-person float-none'} style={{width:'1rem'}}></span> My Profile</Link>
              </li>
              <li 
                className={pageInfo && pageInfo.pageName ==='profile' ? 'active': 'text-muted' } 
                onClick={() => navigate('/profile')}
              >
                Overview
              </li>
              <li 
                id="transactionsNav"
                className={pageInfo && (pageInfo.pageName ==='history' || pageInfo.pageName === 'orderDetails' || pageInfo.pageName ==='pendingOrders' || pageInfo.pageName === 'bonusTransactions') ? 'active': 'text-muted'}
                onClick={showSubMenuClick}
              >
                Transactions<span className={`oi ${pageInfo && (pageInfo.pageName ==='history' || pageInfo.pageName === 'orderDetails' || pageInfo.pageName === 'bonusTransactions') ? 'oi-chevron-bottom' : 'oi-chevron-right'}`}></span>
              </li>
              <ul className={`${pageInfo && (pageInfo.pageName ==='history' || pageInfo.pageName === 'orderDetails' || pageInfo.pageName ==='pendingOrders' || pageInfo.pageName === 'bonusTransactions') ? '' : 'hide'}`} onClick={onSubMenuClick}>
                <li 
                  className={`${pageInfo && (pageInfo.pageName ==='pendingOrders')  ? 'active' : 'text-muted'}`}
                  onClick={()=> navigate('/profile/pending-orders')}
                >Pending Orders</li>
                <li 
                  className={`${pageInfo && (pageInfo.pageName ==='history' || pageInfo.pageName === 'orderDetails') ? 'active' : 'text-muted'}`}
                  onClick={()=> navigate('/profile/history')}
                >History</li>
                <li 
                  className={`${pageInfo && (pageInfo.pageName === 'bonusTransactions') ? 'active' : 'text-muted'}`}
                  onClick={()=> navigate('/profile/bonus')}
                >RDB Dollars</li>
                {/* <li>Sub Menu</li> */}
                {/* <li>Sub Menu</li> */}
              </ul>
              <li 
                className={pageInfo && pageInfo.pageName ==='referrals' ? 'active': 'text-muted' } 
                onClick={() => navigate('/profile/referrals')}
              >
                My Referrals
              </li>
              <li 
                className={pageInfo && pageInfo.pageName ==='myReferrals' ? 'active': 'text-muted'}
              >
                <Link to="/login" className="text-muted">Logout</Link>
              </li> 
            </ul>
            
        </div>
        <Row className="bottomSidebar">
          <Col>
            <ul>
              {/* <li>
                <Link to="/profile/myProfile" className="text-muted"><span className={'oi oi-person float-none'} style={{width:'1rem'}}></span> My Profile</Link>
              </li>  */}
              {/* <li className="text-muted"><span className={'oi oi-question-mark float-none'} style={{width:'1rem'}}></span> Help</li> */}
              {/* <li className="text-muted"><span className={'oi oi-wrench float-none'} style={{width:'1rem'}}></span> Settings</li> */}
              {/* <li className="text-muted"><span className={'oi oi-info float-none'} style={{width:'1rem'}}></span> About</li> */}
              <li className="text-muted">Version 0.0.5</li>
            </ul>
          </Col>
        </Row>
        
        {/* {state ? JSON.stringify(state) : ''} */}
      </Container>
    </>
  )
}

export default UserSidebar