/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Container, Row, Col  } from "react-bootstrap"
import CustomNavbar from "./navBar"
import * as jwtDecode from 'jwt-decode'
import LocalStorage from '../components/localStorage';
import UserSidebar from "./sidebar/userSidebar";
import appInfo from '../../package.json'


export const getDecodedToken = () => {
  if (!LocalStorage.get('jwt')) {
    return false;
  }
  return jwtDecode(LocalStorage.get('jwt')); 
}
export const DecodedTokenContext = React.createContext(getDecodedToken())
DecodedTokenContext.displayName = 'DecodedTokenContext';

const Layout = ({ children, pageInfo }) => {

  return (
    <>
      <DecodedTokenContext.Provider value={getDecodedToken()}>
        {getDecodedToken().role === 'user' && (
        <Container fluid className="px-0 userMain mainUserLayout" >          
          <div className="userSidebarLayout">
              <UserSidebar
                show={true}
                pageInfo={pageInfo}
                token={getDecodedToken()}
              >
              </UserSidebar>
          </div>  
          <div className="userContent">
            <Container fluid className="mt-4">
              <CustomNavbar pageInfo={pageInfo} decodedToken={getDecodedToken()} isUserRole={true}/> 
              <main>{children}</main>
            </Container>
          </div>

          <Row noGutters className="footerCol">
            <Col >
              <footer>
                <span>
                  © {new Date().getFullYear()}, Red Dot Bespoke
                  <br />
                  Version {appInfo.version}
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
        )}
        {getDecodedToken().role !== 'user' && (
          <>
          <Container fluid className="px-0 main" >
            <CustomNavbar pageInfo={pageInfo} decodedToken={getDecodedToken()}/>
            <Row noGutters>
              <Col>
                <Container fluid className="mt-4" style={{paddingBottom:'60px'}}>
                  <main>{children}</main>
                </Container>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="footer-col">
                <footer>
                  <span>
                    © {new Date().getFullYear()}, Red Dot Bespoke
                  </span>
                </footer>
              </Col>
            </Row>
          </Container>
          </>
        )}
      </DecodedTokenContext.Provider>
    </>
  )
  
}

export default Layout
