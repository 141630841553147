import * as jwtDecode from 'jwt-decode'
import LocalStorage from '../components/localStorage';


const getDecodedToken = () => {
  if (!LocalStorage.get('jwt')) {
    return false;
  }
  return jwtDecode(LocalStorage.get('jwt')); 
}

const getUsernameFromToken = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.username
}

const isAdmin = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'admin';
}

const isManager = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'manager';
}

const isSupplier = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'supplier';
}

const isConsultant = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'consultant';
}

const isTailor = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'tailor';
}

const isAmir = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.username === 'amir';
}

const isIsabelle = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.username === 'isabelle';
}


const isRedDotBespoke = () => {
  return isAdmin() || isManager() || isConsultant();
}

const isUser = () => {
  const decodedToken = getDecodedToken();
  return decodedToken && decodedToken.role === 'user';
}

export {isAdmin, isManager, isConsultant, isSupplier, isRedDotBespoke, isTailor, isUser, isAmir, isIsabelle, getUsernameFromToken}
