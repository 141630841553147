import React, {useContext} from "react"
import { Link, navigate } from "gatsby"

import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap"
import icon from '../images/Small-Logo_white_small.png';
import LocalStorage from '../components/localStorage';
import { isAdmin, isManager, isConsultant, isSupplier, isRedDotBespoke, isAmir, isIsabelle, isUser, isTailor } from '../helpers/roles'
import { DecodedTokenContext } from './layout';


const CustomNavbar = ({ pageInfo, isUserRole }) => {
  const decodedToken = useContext(DecodedTokenContext)

  const RDBNav = () => {
    if (!decodedToken) {
      return false;
    }
    
    if (isRedDotBespoke()) {
      return (
        <>
          <Link to="/" className="link-no-style">
            <Nav.Link as="span" eventKey="index">
              Dashboard
            </Nav.Link>
          </Link>
          <NavDropdown title="Orders" id="nav-dropdown">
            <NavDropdown.Item eventKey="orderHistory">Order History</NavDropdown.Item>
            <NavDropdown.Item eventKey="prodsheet">Production Sheet</NavDropdown.Item>
            <NavDropdown.Item eventKey="completedOrders">Completed Orders</NavDropdown.Item>
            {isAdmin() && decodedToken.username !== 'amir' &&
              <>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="pendingRefunds">Pending Refunds</NavDropdown.Item>
              </>
            }
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="manageGroups">Manage Groups</NavDropdown.Item>
            <NavDropdown.Item eventKey="assignOrders">Assign Orders</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="manageDesignSection">Manage Design Section</NavDropdown.Item>
            <NavDropdown.Item eventKey="manageDesignOption">Manage Design Option</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="inPro">InPro</NavDropdown.Item>
            {/* <NavDropdown title="Testing" className="nested-dropdown">
              <NavDropdown.Item eventKey="test" >test</NavDropdown.Item>

            </NavDropdown> */}
          </NavDropdown>
          <NavDropdown title="Customers" id="nav-dropdown">
            <NavDropdown.Item eventKey="customersSummary">Customers Summary</NavDropdown.Item>
          </NavDropdown>
         
          {(isAdmin() || isAmir() || isIsabelle()) && 
            <NavDropdown title="Suppliers" id="nav-dropdown">
              <NavDropdown.Item eventKey="suppliersBillReport">Suppliers Bill Report</NavDropdown.Item>
              {/* <NavDropdown.Item eventKey="reportsAdminSuppliersBill">Suppliers Bill</NavDropdown.Item> */}
              {isAdmin() &&
                <NavDropdown.Item eventKey="suppliersSummary">Suppliers Summary</NavDropdown.Item>
              }
            </NavDropdown>
          }
        </>
      )
    }
    if (isTailor()) {
      return (
        <>
          <Link to="/" className="link-no-style">
            {/* <Nav.Link as="span" eventKey="index">
              Dashboard
            </Nav.Link> */}
          </Link>
          <NavDropdown title="Orders" id="nav-dropdown">
            <NavDropdown.Item eventKey="orderHistory">Order History</NavDropdown.Item>
            <NavDropdown.Item eventKey="prodsheet">Production Sheet</NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item eventKey="manageGroups">Manage Groups</NavDropdown.Item>
            <NavDropdown.Item eventKey="assignOrders">Assign Orders</NavDropdown.Item>
            <NavDropdown.Divider /> */}
            {/* <NavDropdown.Item eventKey="manageDesignSection">Manage Design Section</NavDropdown.Item> */}
            {/* <NavDropdown.Item eventKey="manageDesignOption">Manage Design Option</NavDropdown.Item> */}
          </NavDropdown>
          <NavDropdown title="Customers" id="nav-dropdown">
            <NavDropdown.Item eventKey="customersSummary">Customers Summary</NavDropdown.Item>
          </NavDropdown>
        </>
      )
    }
  };


  const ReportsNav = () => {
    if (!decodedToken) {
      return false;
    }
    
    return (
      <>   
        {isRedDotBespoke() && 
          <NavDropdown title="Reports" id="nav-dropdown">
            {isRedDotBespoke() &&
              <>
                <NavDropdown.Item eventKey="itemsInProduction">Items In Production</NavDropdown.Item>
                <NavDropdown.Item eventKey="consultantOrders">Consultants Orders</NavDropdown.Item>
                <NavDropdown.Item eventKey="fabricOrder">Fabric Order</NavDropdown.Item>
                <NavDropdown.Item eventKey="salesTracking">Sales Tracking</NavDropdown.Item>
                <NavDropdown.Item eventKey="refundedItems">Refunded Items</NavDropdown.Item>
                <NavDropdown.Item eventKey="rPoints">R Points</NavDropdown.Item>
              </>
            }
          </NavDropdown>
        }
        {isTailor() && 
          <NavDropdown title="Reports" id="nav-dropdown">
            
            <>
              {/* <NavDropdown.Item eventKey="itemsInProduction">Items In Production</NavDropdown.Item>
              <NavDropdown.Item eventKey="consultantOrders">Consultants Orders</NavDropdown.Item>
              <NavDropdown.Item eventKey="fabricOrder">Fabric Order</NavDropdown.Item> */}
              <NavDropdown.Item eventKey="salesTracking">Sales Tracking</NavDropdown.Item>
              {/* <NavDropdown.Item eventKey="refundedItems">Refunded Items</NavDropdown.Item> */}
            </>
            
          </NavDropdown>
        }         
      </>
    )
  }
  
  const supplierNav = () => {
    if (!decodedToken) {
      return false;
    }

    if (isSupplier()) {
      return (
        <>
          <NavDropdown title="Suppliers" id="nav-dropdown">
            <NavDropdown.Item eventKey="suppliersProdSheet">Suppliers Production Sheet</NavDropdown.Item>
          </NavDropdown>
        </>
        )
    }
  }

  const usersNav = () => {
    if (!decodedToken) {
      return false;
    }
    
    return (
      isUser() && 
      <Link to="/profile" className="link-no-style">
        <Nav.Link as="span" eventKey="userProfile">
          Home
        </Nav.Link>
      </Link>
    )
  }
  
  const settingsNav = () => {
    return (
      isRedDotBespoke() && 
      <Link to="/settings" className="link-no-style">
        <Nav.Link as="span" eventKey="settings">
          Settings
        </Nav.Link>
      </Link>
    )
  }
  
  
  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case 'prodsheet':
        navigate('/prodsheet');
        break;
      case 'completedOrders':
        navigate('/completedOrders');
        break;
      case 'orderHistory':
        navigate('/orders/history');
        break;
      case 'pendingRefunds':
        navigate('/orders/pendingRefunds');
        break;
      case 'suppliersProdSheet':
        navigate('/suppliers/prodsheet');
        break;
      case 'suppliersSummary':
        navigate('/suppliers/summary');
        break;
      case 'manageGroups':
        navigate('/orderGroupings');
        break;
      case 'manageDesignSection':
        navigate('/designSectionSummary');
        break;
      case 'manageDesignOption':
        navigate('/designOptionsSummary');
        break;
      case 'assignOrders':
        navigate('/orders/tag');
        break;
      case 'reportsAdminSuppliersBill':
        navigate('/reports/admin/suppliersBill');
        break;
      case 'suppliersBillReport':
        navigate('/reports/bill');
        break;
      case 'itemsInProduction':
        navigate('/reports/admin/ItemsInProduction');
        break;
      case 'consultantOrders':
        navigate('/reports/consultants/orders');
        break;
      case 'fabricOrder':
        navigate('/reports/admin/fabricOrder');
        break;
      case 'salesTracking':
        navigate('/reports/consultants/sales');
        break;
      case 'refundedItems':
        navigate('/reports/admin/refundedItems');
        break;
      case 'rPoints':
        navigate('/reports/consultants/rpoints');
        break;
      case 'customersSummary':
        navigate('/customers/summary');
        break;
      case 'orderHistory':
        navigate('/orders/history');
        break;
      case 'inPro':
        navigate('/batchStatusUpdate');
        break;

      default:
        break;
    }
  };

  return (
    <>
      {isUserRole && (
        <Navbar variant="light" expand="lg" onSelect={handleSelect}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=> {
            const element = document.querySelector('.mainUserLayout')
            element.classList.toggle('collapsed')
          }}/>
          
        </Navbar>
      )}

      {!isUserRole && (
       <Navbar variant="light" expand="lg" id="site-navbar" onSelect={handleSelect}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={pageInfo && pageInfo.pageName} variant="pills">
            {isUser() && 
              <Link to="/profile" className="link-no-style">
                <Navbar.Brand as="span"><img src={icon} /></Navbar.Brand>
              </Link>
            }
            {isRedDotBespoke() &&
              <Link to="/" className="link-no-style">
                <Navbar.Brand as="span"><img src={icon} /></Navbar.Brand>
              </Link>
            }
            {!decodedToken && 
               <Navbar.Brand as="span"><img src={icon} /></Navbar.Brand>
            }
          </Nav>
          <Nav activeKey={pageInfo && pageInfo.pageName} variant="pills">
            {RDBNav()}
            {supplierNav()} 
            {ReportsNav()}
            {usersNav()}
            {settingsNav()}
          </Nav>
          <Nav activeKey={pageInfo && pageInfo.pageName} variant="pills" style={{marginLeft: 'auto'}}>
            {(isRedDotBespoke() || isTailor()) && 
              <Link to="/customers/summary" className="link-no-style ">
                <Nav.Link as="span" eventKey="customersSummary">
                  <Button size="sm" variant="primary">New/Repeat Order</Button>
                  </Nav.Link>
                </Link>
              }
              {(isRedDotBespoke() || isTailor() || isUser () ) && 
                <>
                  <NavDropdown title={decodedToken && decodedToken.username ? decodedToken.username: ''} id="nav-dropdown">
                    <NavDropdown.Item eventKey="profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item eventKey="profileSettings">Settings</NavDropdown.Item>
                  </NavDropdown>
                  <Link to="/login" className="link-no-style ">
                    <Nav.Link as="span" eventKey="login">
                      {LocalStorage.get('jwt') ? 'Logout' : 'Login'}
                    </Nav.Link>
                  </Link>
                </>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  )
}

export default CustomNavbar
